<template>
  <div class="m-input-autocomplete">
    <div
      class="sf-input-has-clear"
      v-click-outside="closeHandler"
    >
      <SfInput
        ref="input"
        :label="currentLabel"
        @input="onInput"
        class="sf-input--filled"
        :class="inputClass"
        :value="inputValue"
        :disabled="disabled"
        :placeholder="prevInputValue[property]"
        data-transaction-name="Autocomplete - Field"
        :valid="valid"
        @focus="onFocus"
        @blur="onBlur"
      />
      <div class="m-input-autocomplete__chevron" @click="toggleAutocomplete">
        <div
          class="sf-chevron sf-select__chevron"
          :class="chevronClass"
        />
      </div>
      <div
        v-if="isOpenAutocomplete"
        class="m-input-autocomplete__autocomplete"
      >
        <p
          class="m-input-autocomplete__autocomplete-no-result"
          v-if="isNoResult"
        >
          {{ message }}
        </p>
        <ul
          class="m-input-autocomplete__autocomplete-result"
          v-else
        >
          <li
            v-for="(item, i) in dataListResults"
            :key="item.id || i"
            class="m-input-autocomplete__autocomplete-item"
          >
            <slot name="item" v-bind="{ item, property, setInputValue }">
              <button
                class="m-input-autocomplete__list-item"
                data-transaction-name="Autocomplete - Set Input"
                @click="setInputValue(item)"
              >
                {{ item[property] }}
              </button>
            </slot>
          </li>
        </ul>
      </div>
    </div>

    <div class="sf-input__error-message">
      <transition name="fade">
        <!-- @slot Custom error message of form input -->
        <slot v-if="!valid" name="error-message" v-bind="{ errorMessage }">
          <div>{{ errorMessage }}</div>
        </slot>
      </transition>
    </div>
  </div>
</template>
<script>
import SfInput from 'theme/components/storefront-override/SfInput'
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import Fuzzy from 'fuzzy'

export default {
  name: 'MInputAutocomplete',
  components: { SfInput },
  props: {
    /**
     * selected object
     */
    selected: {
      type: [Object, null],
      default: () => ({})
    },
    /**
     * objects array
     */
    dataList: {
      type: Array,
      default: () => [],
      required: true
    },
    /**
     * property for value
     */
    property: {
      type: String,
      default: '',
      required: true
    },
    /**
     * label
     */
    label: {
      type: String,
      default: '',
      required: true
    },
    /**
     * other label
     */
    labelTop: {
      type: String,
      default: '',
      required: false
    },
    /**
     * message if there are no results
     */
    message: {
      type: String,
      default: '',
      required: true
    },
    /**
     * limit if list so long
     */
    limit: {
      type: Number,
      default: () => 0
    },
    /**
     * Validate value of form input
     */
    valid: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Error message value of form input. It will be appeared if `valid` is `true`.
     */
    errorMessage: {
      type: String,
      default: null
    },
    /**
     * border highlight
     */
    highlight: {
      type: Boolean,
      default: () => false
    }
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      isOpenAutocomplete: false,
      inputValue: '',
      prevInputValue: {},
      isInputFilled: false,
      focused: false
    }
  },
  watch: {
    selected () {
      this.prevInputValue = this.selected ? { ...this.selected } : {}
    }
  },
  computed: {
    dataListResults () {
      if (!this.inputValue) {
        return this.limit
          ? this.dataList.slice(0, this.limit)
          : this.dataList
      }

      let results = Fuzzy.filter(this.inputValue, this.dataList, {
        extract: (el) => el[this.property]
      })

      if (this.limit) {
        results = results.slice(0, this.limit)
      }

      return results.map(el => el.original)
    },
    currentLabel () {
      return this.selected?.[this.property] ? this.otherLabel : this.label
    },
    otherLabel () {
      return this.labelTop ? this.labelTop : this.label
    },
    isNoResult () {
      return !this.dataListResults.length;
    },
    inputClass () {
      return {
        'sf-input--has-text': this.prevInputValue[this.property],
        'sf-input--focused': this.highlight && (this.focused || this.isOpenAutocomplete)
      }
    },
    chevronClass () {
      return {
        'sf-chevron--active': this.isOpenAutocomplete
      }
    }
  },
  methods: {
    toggleAutocomplete () {
      if (this.disabled) return

      this.isOpenAutocomplete = !this.isOpenAutocomplete

      if (!this.isOpenAutocomplete) {
        this.inputValue = ''
      } else {
        this.focusInput()
      }
    },
    focusInput () {
      this.$refs.input?.$el?.getElementsByTagName('input')?.[0]?.focus()
    },
    setInputValue (value) {
      this.isOpenAutocomplete = false
      this.prevInputValue = value
      this.inputValue = ''
      this.isInputFilled = true
      this.$emit('set-value', value)
    },
    onFocus (value) {
      this.focused = true
      this.isOpenAutocomplete = true
      const that = value.currentTarget;
      that.selectionStart = that.selectionEnd = this.inputValue.length;
    },
    onBlur () {
      this.focused = false
    },
    onInput (value) {
      this.inputValue = value
      this.isOpenAutocomplete = true
      this.isInputFilled = false
    },
    closeHandler () {
      if (!this.isOpenAutocomplete) {
        return
      }

      this.isOpenAutocomplete = false

      if (this.isInputFilled || !this.prevInputValue[this.property]) {
        return
      }

      if (!this.dataListResults.length) {
        this.isInputFilled = false
        this.$emit('set-value', this.prevInputValue)
      }

      this.inputValue = ''
    }
  },
  mounted () {
    this.inputValue = ''
    this.prevInputValue = this.selected ? { ...this.selected } : {}
    this.isInputFilled = !!this.prevInputValue[this.property]
  }
}
</script>

<style lang="scss">
@import "$ui/styles/components/molecules/SfSelect.scss";
</style>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-input-autocomplete {
  &__chevron {
    width: var(--spacer-45);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    cursor: pointer;
  }

  &__autocomplete {
    font-size: var(--font-sm);
    font-weight: var(--font-normal);
    line-height: 1.05rem;
    list-style: none;
    padding: 0;
    background: var(--light-gray);
    margin: 2px 0 0 0;
    top: 100%;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    z-index: var(--autocomplete-index);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.06);

    li {
      cursor: pointer;
    }

    &-result {
      padding: 0;
      margin: 0;
      max-height: 10.938rem;
      overflow-y: auto;
      overflow-x: hidden;
      list-style: none;
      box-sizing: border-box;
    }

    &-item {
      outline: none;
      -webkit-tap-highlight-color: rgba(0,0,0,0);

      ::v-deep .m-input-autocomplete__list-item, ::v-deep .m-input-autocomplete__list-wrapper {
        padding: var(--spacer-10) var(--spacer-15);
        display: block;
      }

      ::v-deep .m-input-autocomplete__list-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
        text-align: left;
        border: 0;
        outline: none;
        width: 100%;
        font-size: var(--font-sm);
        background-color: transparent;
        color: var(--black);

        &:hover:not(:disabled):not(.shp-area-nova__input--error) {
          color: var(--orange);
        }
      }

      &:active, &:focus {
        outline: none;
      }
    }

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-left: 3px solid var(--light-orange);
      background-clip: padding-box;

      &:hover {
        border-left-color: var(--orange);
      }
    }

    &-no-result {
      margin: 0;
      padding: var(--spacer-15);
    }
  }

  ::v-deep {
    .sf-input-has-clear .sf-input {
      &__error-message {
        display: none;
      }

      &__label {
        width: calc(100% - var(--spacer-30));
      }

      &--filled input{
        &::placeholder {
          color: var(--black);
        }
      }

      input {
        --input-padding: 1.5rem var(--spacer-45) .69rem var(--spacer-15);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:focus::placeholder {
          color: var(--input-label-color, inherit);
        }
      }
    }
  }

  .sf-chevron {
    right: var(--spacer-sm);
    width: 0;
    height: 0;
    border-left: 0.375rem solid transparent;
    border-right: 0.375rem solid transparent;
    border-bottom: 0.375rem solid var(--black);
    transform: translate3d(0, -50%, 0) rotate(180deg) scale(1);
    user-select: none;
    pointer-events: none;
    cursor: default;
    z-index: 0;

    &--active {
      transform: translate3d(0, -50%, 0) rotate(0deg) scale(1);
    }
  }
}

::v-deep {
  .sf-input__wrapper {
    margin-bottom: 0;
  }

  .sf-input {
    transition: border 200ms;
    border: 1px solid var(--light-gray);
  }

  .sf-input--focused {
    border: 1px solid var(--orange)
  }
}
</style>
